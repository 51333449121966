import * as React from 'react';
import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { Form as RemixForm, useBlocker } from '@remix-run/react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '~/components/AlertDialog';

import { CardLabel, Label } from '~/components/Label';
import { cn } from '~/utils/cn';
import { createValidationHandler } from './form/use-field-validation';

type FormItemContextValue = {
  formItemId: string;
  error: [
    string | null | undefined,
    React.Dispatch<React.SetStateAction<string | null | undefined>>,
  ];
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

function useFormField() {
  const context = React.useContext(FormItemContext);

  return {
    ...context,
    props: {
      onInvalid: context?.error?.[1] ? createValidationHandler(context?.error[1]) : undefined,
      onInput: context?.error?.[1] ? createValidationHandler(context?.error[1]) : undefined,
    },
  };
}

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const errorState = React.useState<string | null | undefined>(undefined);
    const parentContext = useFormField(); // Only to retrieve error state

    return (
      <FormItemContext.Provider
        value={{ formItemId: React.useId(), error: parentContext?.error || errorState }}
      >
        <div ref={ref} className={cn('space-y-2', className)} {...props} />
      </FormItemContext.Provider>
    );
  },
);
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ ...props }, ref) => {
  const { formItemId } = useFormField();

  return <Label ref={ref} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = 'FormLabel';

const FormCardLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ ...props }, ref) => {
  const { formItemId } = useFormField();

  return <CardLabel ref={ref} htmlFor={formItemId} {...props} />;
});
FormCardLabel.displayName = 'FormCardLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ children, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Slot ref={ref} id={formItemId} aria-invalid={!!error[0]} {...props}>
      {children}
    </Slot>
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />;
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error } = useFormField();
  const body = error[0] ? String(error[0]) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      className={cn(
        'animate-smooth-height overflow-hidden text-sm font-medium text-destructive fill-mode-both',
        className,
      )}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

function Form({
  warnOnUnsavedChanges = false,
  ...props
}: React.ComponentPropsWithoutRef<typeof RemixForm> & { warnOnUnsavedChanges?: boolean }) {
  const [dirty, setDirty] = React.useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      warnOnUnsavedChanges && dirty && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <>
      {blocker.state === 'blocked' ? (
        <AlertDialog defaultOpen>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                You have unsaved changes. Leave anyway?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => blocker.reset()}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => blocker.proceed()}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : null}

      <RemixForm
        {...props}
        onChange={(e) => {
          props.onChange?.(e);
          if (warnOnUnsavedChanges) setDirty(true);
        }}
        onSubmit={(e) => {
          props.onSubmit?.(e);
          if (warnOnUnsavedChanges) setDirty(false);
        }}
      />
    </>
  );
}

export {
  Form,
  FormItem,
  FormLabel,
  FormCardLabel,
  FormControl,
  FormDescription,
  FormMessage,
  useFormField,
};
